import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import Slider from 'react-slick';

const References: React.FC = () => {
  const settings = {
    className: 'center',
    centerMode: true,
    infinite: true,
    slidesToShow: 4,
    speed: 500,
    responsive: [
      {
        breakpoint: 1500,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 780,
        settings: {
          slidesToShow: 1,
          arrows: false,
        },
      },
    ],
  };

  const {
    allContentfulReferences: { nodes: references },
  } = useStaticQuery(graphql`
    {
      allContentfulReferences {
        nodes {
          id
          name
          text {
            text
          }
        }
      }
    }
  `);

  return (
    <div className="references">
      <h1 className="text-white text-center mb-10 text-3xl">Napísali o nás</h1>
      <Slider {...settings} className="flex">
        {references.map((reference) => (
          <div className="h-full" key={reference.id}>
            <div className="bg-white p-4 text-center mx-2 md:mx-6 shadow-2xl h-full rounded-md">
              <div className="mb-4 font-semibold">{reference.name}</div>
              <p>{`"${reference.text.text}"`}</p>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default References;
