import React from 'react';
import { PageProps } from 'gatsby';

import MainLayout from '../components/main-layout';
import Carousel from '../components/home/carousel';
import HomeDescription from '../components/home/home-description';
import References from '../components/home/references';
import SEO from '@/components/seo';

const Home: React.FC<PageProps> = () => (
  <>
    <SEO />
    <MainLayout>
      <Carousel />
      <div className="container my-10 text-center m-auto md:px-20 px-4">
        <HomeDescription />
      </div>
      <div className="pb-10">
        <References />
      </div>
    </MainLayout>
  </>
);

export default Home;
