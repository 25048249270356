import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import React from 'react';
import Slider from 'react-slick';

const settings = {
  arrows: true,
  infinite: true,
  autoplay: true,
  fade: true,
  autoplaySpeed: 5000,
};

const Carousel: React.FC = () => {
  const {
    contentfulCarousel: { images },
  } = useStaticQuery(graphql`
    {
      contentfulCarousel {
        images {
          id
          gatsbyImageData(placeholder: BLURRED)
        }
      }
    }
  `);

  return (
    <Slider {...settings} className="overflow-hidden">
      {images.map((image, index) => (
        <GatsbyImage
          key={image.id}
          image={getImage(image)}
          alt={`carousel-${index}`}
          className="h-75-screen"
        />
      ))}
    </Slider>
  );
};

export default Carousel;
