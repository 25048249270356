import React from 'react';

const HomeDescription: React.FC = () => (
  <div className="text-white">
    <div
      className="mb-10 m-auto bg-white"
      style={{
        width: '80px',
        height: '10px',
      }}
    />
    Vitajte na stránke BIM House, kde sa vám otvárajú možnosti na zrealizovanie
    vášho vysnívaného projektu. Pod jednou &quot;strechou&quot; nájdete dôležité
    informácie, o ktorých by ste mali mať prehľad ešte pred začatím realizácie
    vybranej stavby. Naše služby sú výnimočné aj vďaka inovatívnemu spôsobu
    vizualizácie jednotlivých projektov. Služby, ktoré vám ponúkame sú založené
    na kvalitnom spracovaní a zahŕňajú v sebe celkový doprovod od prvej
    konzultácie až po vydanie finálneho stavebného povolenia. Veríme, že si na
    našej stránke nájdete práve to, čo potrebujete a už vopred sa tešíme, ak
    budeme môcť byť súčasťou pri tvorbe vašej vysnívanej stavby.
    <div
      className="mt-10 m-auto bg-white"
      style={{
        width: '80px',
        height: '10px',
      }}
    />
  </div>
);

export default HomeDescription;
